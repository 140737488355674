<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('license_management.forwarding_list') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
         <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
            label-for="office_id"
            >
              <template v-slot:label>
                {{ $t('allocation_dc_office.dc_office') }}
              </template>
              <b-form-select
                plain
                id="office_id"
                :options="dcOfficeList"
                v-model="search.office_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-group
                label-for="memo_no"
                >
                <template v-slot:label>
                    {{ $t('license_management.memorial_no') }}
                </template>
                <b-form-input
                    id="memo_no"
                    v-model="search.memo_no"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>
            <b-col sm="4" class="mt-4">
                <b-button size="sm" variant="primary" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
            </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('license_management.forwarding_list') }}</h4>
      </template>
      <template v-slot:headerAction>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(memo_no)="data">
                    {{ $i18n.locale === 'bn' ? helpers.convertEnglishToBanglaNumber(data.item.memorial_no) : data.item.memorial_no}}
                  </template>
                  <template v-slot:cell(date)="data">
                    {{ data.item.date | dateFormat }}
                  </template>
                  <template v-slot:cell(dc_office)="data">
                    {{ getOfficeName(data.item) }}
                  </template>
                  <template v-slot:cell(action)="data">
                      <a @click="getLetter(data.item.dc_office_status, data.item.date, data.item.district_id)" class="btn btn-info">{{$t('license_management.ins_letter')}} <i class="ri-file-download-fill"></i></a>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { tcbForwardLetterList, inspectionLetterPdf, getDistrictWiseRegion } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import userTypeModule from '@/utils/userType'
import { helpers } from '@/utils/helper-functions'

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      licenseRegistrationServiceBaseUrl: licenseRegistrationServiceBaseUrl,
      helpers: helpers,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      applications: [],
      search: {
       memo_no: '',
       office_id: 0,
       limit: 10
      },
      districtList: [],
      upazilaList: [],
      regionalOffices: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'license_management.memorial_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'allocation_dc_office.dc_office', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 8, thStyle: { width: '10%' } }
      ],
      actions: {
        edit: false,
        details: false,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.getRegionalOffices()
    this.loadData()
  },
  mounted () {
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    loggedUserPrivilege: function () {
      return this.$store.state.BazarMonitoringService.commonObj.loggedUserPrivilege
    },
    addressCustomUser: function () {
      return this.$store.state.BazarMonitoringService.commonObj.addressCustomUser
    },
    dcOfficeList: function () {
      return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === 23)
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'memo_no' },
          { key: 'date' },
          { key: 'dc_office' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'memo_no' },
          { key: 'date' },
          { key: 'dc_office' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('orgProfile.org_profile') + ' ' + this.$t('globalTrans.entry') : this.$t('orgProfile.org_profile') + ' ' + this.$t('globalTrans.modify')
    }
  },
  methods: {
    editMethodInParent (item, type) {
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, {
         page: this.pagination.currentPage,
         per_page: this.search.limit,
         is_office_user: userTypeModule.userType('is_office_user')
      })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, tcbForwardLetterList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index, select: 0 })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async getRegionalOffices () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, getDistrictWiseRegion)
      if (result.success) {
        this.regionalOffices = result.data
      }
    },
    getOfficeName (itemData) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === itemData.office_id)
      itemData.district_id = data.district_id
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    findRegionalOffice (districtId = 0) {
        if (districtId) {
          for (const office of this.regionalOffices) {
              const districtExists = office.district_details.some(detail => detail.district_id === districtId)
              if (districtExists) {
                  return office.regional_office_id
              }
          }
          return 'District not found in any regional office'
        }
        return 'District not found in any regional office'
    },
    async getLetter (dcOfficeStatus, letterDate, districtId) {
      const regionalOfficeId = this.findRegionalOffice(districtId)
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, dc_office_status: dcOfficeStatus, letter_date: letterDate, regional_office_id: regionalOfficeId, district_id: districtId })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(licenseRegistrationServiceBaseUrl, inspectionLetterPdf, params)
      var blob = new Blob([result], {
            type: 'application/pdf'
        })
        this.loading = false
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>

<style>
.btn-success {
  background-color: var(--success) !important;
  border-color: var(--success);
}
</style>
